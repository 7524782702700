import {
  CustomInputProps,
  DEFAULT_BLOCKOPTIONS_DEFAULTS,
} from "@rubendenbrok/bloqmatter-cms"
import { basicTextBoxBlock_inputs } from "./components/displayBlocks/BasicTextBoxBlock"
import { TitleBlock_Inputs } from "./components/displayBlocks/BasicTitleBlock"
import { bookYourAppointmentBlock_inputs } from "./components/displayBlocks/BookYourAppointmentBlock"
import { HeroBlock_Inputs } from "./components/displayBlocks/HeroBlock"
import { MediaTextBlock_Inputs } from "./components/displayBlocks/MediaTextBlock"
import { MediaTextTwoColorsBlock_Inputs } from "./components/displayBlocks/MediaTextTwoColorsBlock"
import { narrowMediaTextBlock_Inputs } from "./components/displayBlocks/NarrowMediaTextBlock"
import { ShopModuleBlock_Inputs } from "./components/displayBlocks/ShopModuleBlock"
import { TitleWithResponsiveTriangleBlock_inputs } from "./components/displayBlocks/TitleWithResponsiveTriangleBlock"
import { TriangleMediaTextBlock_Inputs } from "./components/displayBlocks/TriangleMediaTextBlock"
import { TriangleTextBlock_Inputs } from "./components/displayBlocks/TriangleTextBlock"
import { extraHeaderInputs } from "./components/header/DesktopHeader"
import {
  decorationTriangleDefaults,
  decorationTriangleInputs,
} from "./components/molecules/DecorationTriangle"
import { footer_inputs } from "./components/molecules/Footer"
import BLOCKOPTIONS from "./custom_blockoptions_inputs"

/*
InputProps : object describing single input -- see below
CustomInput : {
    index : number <- Index at which the input will be inserted
    input: InputProps <- Actual input
    }
AddInputGroupType : {
    [Input Group Name] : CustomInput[]
    }
ReplaceInputGroupType : {
    [Input Group Name] : InputProps[]
    }
AddToBlockOptionsType: [
    {tag: string <- Category name, will also be displayed for user,
    options: CustomInput[]},
    ]
ReplaceBlockOptionsType: [
    {tag: string <- Category name, will also be displayed for user,
    options: InputProps[]},
    ]
AddInputBlockType: {
    [CustomBlockKey]: 
        name: string
        inputs: CustomInput[]
        tags: string[]
        description: string
        defaults: {default values for block.blockContent}
    --- it is possible to omit either 'inputs' or 'defaults' to just add inputs, or just update block defaults ---
ReplaceInputBlockType: {
    [CustomBlockKey]:
        name: string
        inputs: InputProps[],
        tags: string[]
        description: string
        defaults: {default values for block.blockContent}
    }
}

addHeaderInputs :           (InputProps|CustomInput)[] | undefined
replaceHeaderInputs :       InputProps[] | undefined
addFooterInputs :           InputProps|CustomInput[] | undefined
replaceFooterInputs :       InputProps[] | undefined
addPageOptions:             CustomInput[] | undefined
replacePageOptions:         InputProps[] | undefined
addPagePreviewOptions:      CustomInput[] | undefined
replacePagePreviewOptions:  InputProps[] | undefined
addToInputGroups:           AddInputGroupType | undefined
replaceToInputGroups:       ReplaceInputGroupType | undefined
addToBlockOptions:          AddToBlockOptionsType | undefined
replaceBlockOptions:        ReplaceToBlockOptionsType | undefined
addInputblocks:             AddInputBlockType | undefined
replaceInputblock:          replaceInputblockType | undefined


*/

const CUSTOM_CMS_INPUTS: CustomInputProps = {
  addHeaderInputs: [...extraHeaderInputs],
  replaceHeaderInputs: undefined,
  addFooterInputs: [...footer_inputs],
  replaceFooterInputs: undefined,
  addPageOptions: undefined,
  replacePageOptions: undefined,
  addPagePreviewOptions: undefined,
  replacePagePreviewOptions: undefined,
  addToInputGroups: undefined,
  replaceInputGroups: {
    titleInputGroup: [
      { inputType: "text", keyName: "title", label: "Title line 1" },
      { inputType: "text", keyName: "titleLine2", label: "Title line 2" },
      {
        inputType: "checkbox",
        keyName: "smallerTitle",
        label: "Smaller title",
      },
      { inputType: "richText", keyName: "subtitle", label: "Subtitle" },
      {
        inputType: "conditional",
        keyName: "displayTopTitleTag",
        label: "Display top title tag",
        childInputs: [
          {
            inputType: "text",
            keyName: "topTitleTagText",
            label: "Top title tag text",
          },
          { inputType: "color", keyName: "topTitleTagColor", label: "Color" },
        ],
      },
    ],
  },
  addToBlockOptions: [{ ...decorationTriangleInputs }, ...BLOCKOPTIONS],
  replaceBlockOptions: undefined,
  addInputBlocks: [
    { ...TitleBlock_Inputs },
    { ...TitleWithResponsiveTriangleBlock_inputs },
    { ...MediaTextBlock_Inputs },
    { ...basicTextBoxBlock_inputs },
    { ...HeroBlock_Inputs },
    { ...MediaTextTwoColorsBlock_Inputs },
    { ...TriangleMediaTextBlock_Inputs },
    { ...TriangleTextBlock_Inputs },
    { ...ShopModuleBlock_Inputs },
    { ...bookYourAppointmentBlock_inputs },
  ],
  replaceInputBlocks: undefined,
}

export default CUSTOM_CMS_INPUTS

/*
---------------------
InputProps
Object which defines different kinds of modular user inputs
---------------------

BASIC KEYS:
inputType : string - defines the kind of input

label : string - label the user will see

keyName: string - on change each input will trigger it's handleChange 
    function, providing an update in the form of {[keyName]: (new Value)}

hoverHelp: ReactNode - if a value is provided here, a help icon will apear next 
    to the label, which offers extra explanation on hover.



----------------------
INPUT TYPES:
- these are possible values for 'inputType'. Some input types require additional keys to work.
----------------------
'text'

'richText'

'checkbox'

'radiobuttons'
    EXTRA KEYS:
    labelArr : string[] - array of labels for each of the radio button options
    valueArr? : any[] - array of values, corresponding to the labels with the same index. 
        If left empty, each value will just be its index in the array. 

'color' - lets the user pick one of the colors from cssVariables.colors
    EXTRA KEYS:
    extraLabels : string[] - array of extra labels, wich will be placed as options next the colors
    extraVals: string[] - array of values for the extra labels
        these extra values are usable if you don't want to pass a specific color, but instead want to be 
        able to pass css strings like 'inherit' or 'none'
        //THIS IS NOT PROPERLY IMPLEMENTED IN RENDER BLOCKS YET//

'slider'
    EXTRA KEYS:
    min : number - min value for slider
    max : number - max value for slider

'image' - lets the user select or upload an image, and returns an ImageData object

'inputArray' - An array to which the user can add, remove, or reorder items
    EXTRA KEYS:
    itemName : string - name for each item the user will see in the UI: 'Add new [itemName]'
    childInputs : InputProps[] - array of inputs each child must have
    childNameKey : string - 'keyName' property of one of the childInputs. 
        This property will be used to label the array children. Optional.

'link' - lets the user select another page in the website to link to, or enter an external URL.
    returns Link object:
    {
        external : boolean - is this an external or internal link?
        href : string - href to be used in case of external link
        targetId : string - ID of target page in case of internet link
        text : string - link text the user will see on the site
    }

'conditional' - shows a checkbox input, if this checkbox is checked extra child inputs will be shown
    EXTRA KEYS:
    childInputs : InputProps[] - array of inputs to be shown when checkbox is checked --> {[keyname]:true} 

'expandableInputs' - Purely cosmetical. This isn't an actual input, but hides its childInputs behind an expandable text
    EXTRA KEYS:
    childInputs : InputProps[] - array of inputs to be shown when expanded


instead of passing an individual input, you can also refer to an input group. 
to do this pass an object in the form 
{
    inputGroupName : string -  name of input group
    identifier : string - each individual input in an input group already has a preset keyName. 
        the identifier will be appended to the keyNames, which makes it possible to use an input 
        group multiple times in the same component.
}
*/
